import './ProductItem.css';
import React from 'react';
import { Link } from 'react-router-dom';

const ProductItem = ({product, className, onAdd}) => {
    const onAddHandler = () => {
        onAdd(product)
    }
    return (
        <div className={'product' + className}>
            <Link to={'/advert/'+product.id} className={'product-img__wrp'}>
                <img src={product.thumb} className={'img'} alt="logo" loading="lazy"/>
            </Link>
            <div className={'title'}>{product.name}</div>
            <div className={'price'}>
                <span><b>{product.priceRange}</b></span>
            </div>
            <Link to={'/advert/'+product.id} className={'add-btn'}>Подробнее</Link>
        </div>
    );
};

export default ProductItem;