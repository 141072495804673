import React from "react";
import {useTelegram} from "../../hooks/useTelegram";
import Button from "../Button/Button";
import './Header.css';

const Header = (props) => {
    const {user, onClose} = useTelegram()
    return (<div className={'header'}>
        {/*<Button onClick={onClose}>Закрыть</Button>*/}
        {/*<span className={'username'}>Вы вошли как: {user?.username}</span>*/}
    </div>)
}

export default Header;