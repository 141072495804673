import React, {useCallback, useEffect, useState} from "react"
import './Form.css'
import { useTelegram } from "../../hooks/useTelegram"
import { useNavigate } from "react-router-dom";


const Form = () => {
    const navigate = useNavigate()
    const {tg, queryId} = useTelegram()
    const [name, setName] = useState(tg.initDataUnsafe?.user?.first_name +' '+ tg.initDataUnsafe?.user?.last_name)
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [myTg, setMyTg] = useState('@'+tg.initDataUnsafe?.user?.username)

    // const [city, setCity] = useState('');
    // const [street, setStreet] = useState('');
    // const [subject, setSubject] = useState('physical ');


    // const onSendData = useCallback(() => {
    //     const data = {
    //         city,
    //         street,
    //         subject
    //     }
    //     tg.sendData(JSON.stringify(data));
    // }, [city, street, subject]);
    const onNavigateBack = useCallback(() => {
        tg.BackButton.hide()
        navigate("/")
    }, [])

    const onSendData = useCallback(() => {
        const data = {
            name,
            phone,
            email,
            myTg,
            queryId
        }

        fetch('https://back.gromovbureau.ru/web-app-data', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        // tg.sendData(JSON.stringify(data));
    }, [name, phone, email, myTg, queryId])

    useEffect(() => {
        tg.onEvent('mainButtonClicked', onSendData)
        tg.onEvent('backButtonClicked', onNavigateBack)
        return () => {
            tg.offEvent('mainButtonClicked', onSendData)
            tg.offEvent('backButtonClicked', onNavigateBack)
        }
    }, [onSendData])

    useEffect(() => {
        tg.MainButton.setParams({
            text: 'Заказать звонок'
        });
    }, [])

    // useEffect(() => {
    //     if (!city || !street) {
    //         tg.MainButton.hide();
    //     } else {
    //         tg.MainButton.show();
    //     }
    // }, [city, street]);

    useEffect(() => {
        if (!name || !phone || !email || !myTg) {
            tg.MainButton.hide();
        } else {
            tg.MainButton.show();
        }
    }, [name, phone, email, myTg])
    const onChangeName = (e) => {
        setName(e.target.value)
    }
    const onChangePhone = (e) => {
        setPhone(e.target.value)
    }
    const onChangeEmail = (e) => {
        setEmail(e.target.value)
    }
    const onChangeMyTg = (e) => {
        setMyTg(e.target.value)
    }
    // const onChangeCity = (e) => {
    //     setCity(e.target.value)
    // }
    //
    // const onChangeStreet = (e) => {
    //     setStreet(e.target.value)
    // }
    //
    // const onChangeSubject = (e) => {
    //     setSubject(e.target.value)
    // }

    return (
        <div className={'form'}>
            <h3>Введите ваши данные для связи</h3>
            <input type="text"
                   className={'input'}
                   placeholder={'Ваше имя'}
                   value={name}
                   onChange={onChangeName}/>
            <input type="text"
                   className={'input'}
                   placeholder={'Ваш телефон'}
                   value={phone}
                   onChange={onChangePhone}/>
            <input type="text"
                   className={'input'}
                   placeholder={'Ваш email'}
                   value={email}
                   onChange={onChangeEmail}/>
            <input type="text"
                   className={'input'}
                   placeholder={'Ваш telegram'}
                   value={myTg}
                   onChange={onChangeMyTg}/>

            {/*<input type="text"*/}
            {/*       className={'input'}*/}
            {/*       placeholder={'Город'}*/}
            {/*       value={city}*/}
            {/*       onChange={onChangeCity}/>*/}
            {/*<input type="text"*/}
            {/*       className={'input'}*/}
            {/*       placeholder={'Улица'}*/}
            {/*       value={street}*/}
            {/*       onChange={onChangeStreet}/>*/}
            {/*<select className={'select'}*/}
            {/*        value={subject}*/}
            {/*        onChange={onChangeSubject}>*/}
            {/*    <option value={'physical'}>Физ. лицо</option>*/}
            {/*    <option value={'legal'}>Юр. лицо</option>*/}
            {/*</select>*/}
        </div>
    )
}

export default Form