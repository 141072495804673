import './App.css';
import React, {useEffect} from "react";
import {useTelegram} from "./hooks/useTelegram";
import { Routes, Route } from 'react-router-dom';
import Header from "./components/Header/Header";
import ProductList from "./components/ProductList/ProductList";
import Form from "./components/Form/Form";
import Advert from "./components/Advert/Advert";

function App() {
    const {tg, onToggleButton} = useTelegram();

    useEffect(() => {
        tg.ready();
    }, [])

  return (
    <div className="App">
        <Header/>
        <Routes>
            <Route path='advert/:id' element={<Advert />} />
            <Route path='form' element={<Form />} />
            <Route index element={<ProductList />} />
        </Routes>
    </div>
  );
}

export default App;
