import React, { useCallback, useEffect, useState } from "react"
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios"
import './Advert.css'
import { useTelegram } from "../../hooks/useTelegram"

const Advert = (props) => {
    let { id } = useParams()
    let { tg } = useTelegram()
    const navigate = useNavigate()

    const [adv, setAdvert] = useState(null)

    const onNavigateBack = useCallback(() => {
        tg.BackButton.hide()
        navigate("/")
    }, [])

    useEffect(() => {
        // console.log(`/something/${id}`)
        tg.BackButton.show()
        axios.get(`https://back.gromovbureau.ru/api/adverts/${id}`).then(response => {
            setAdvert(response.data)
        }, [])

        tg.onEvent('backButtonClicked', onNavigateBack)
        return () => {
            tg.offEvent('backButtonClicked', onNavigateBack)
        }
    },[onNavigateBack]);

    if (!adv) return null;

    return (
        <div className={'advert'}>
            <img src={adv.photo} alt="logo"/>
            <div className={'advert__name'}>{adv.name}</div>
            <div className={'advert__price'}>{adv.price} rub.</div>
            <Link to={'/form'} className={'advert__callback'}>заказать звонок</Link>
        </div>
    )
}

export default Advert;