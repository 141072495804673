import './ProductList.css';
import React, {useCallback, useEffect, useState} from "react";
import ProductItem from "../ProductItem/ProductItem";
import {useTelegram} from "../../hooks/useTelegram";
import axios from "axios";

const products = [
    {id: 1, title: 'Джинсы', description: 'синего цвета, прямые', price: 5000},
    {id: 2, title: 'Куртка', description: 'тропентарн, M65', price: 12000},
    {id: 3, title: 'Джинсы2', description: 'синего цвета, прямые', price: 3000},
    {id: 4, title: 'Куртка3', description: 'черная, куртка пилот', price: 15000},
    {id: 5, title: 'Джинсы1', description: 'синего цвета, прямые', price: 7000},
    {id: 6, title: 'Куртка5', description: 'черно/оранжевая, аляска', price: 24000},
    {id: 7, title: 'Джинсы3', description: 'синего цвета, прямые', price: 6000},
    {id: 8, title: 'Куртка1', description: 'олива, бомбер', price: 4000},
];

const getTotalPrice = (items) => {
    return items.reduce((acc, item) => {
        acc += item.price;
    }, 0);
}

const ProductList = () => {
    const [addedItems, setAddedItems] = useState([])
    const [items, setItems] = useState([])

    const {tg, queryId} = useTelegram();

    useEffect(() => {
        axios.get(`https://back.gromovbureau.ru/api/adverts`).then(response => {
            setItems(response.data)
        }, [])
    },[]);

    const onSendData = useCallback(() => {
        const data = {
            products: addedItems,
            getTotalPrice: getTotalPrice(addedItems),
            queryId
        }

        fetch('https://back.gromovbureau.ru/api/adverts', {
            method: 'POST',
            headers: {
                'Content-Type': 'appication/json',
            },
            body: JSON.stringify(data)
        })
    }, [addedItems]);

    useEffect(() => {
        tg.onEvent('mainButtonClicked', onSendData);
        return () => {
            tg.offEvent('mainButtonClicked', onSendData);
        }
    }, [onSendData]);

    const onAdd = (product) => {
        const alreadyAddded = addedItems.find(item => item.id === product.id);
        let newItems = [];

        if (alreadyAddded) {
            newItems = addedItems.filter(item => item.id !== product.id);
        } else {
            newItems = [...addedItems, product];
        }

        setAddedItems(newItems);

        if (newItems.length === 0) {
            tg.MainButton.hide();
        } else {
            tg.MainButton.show();
            tg.MainButton.setParams({
                text: `Купить ${getTotalPrice(newItems)}`
            })
        }
    }

    return (
        <div className={'list'}>
            {items.map(item => (
                <ProductItem product={item} key={item.id} className={'item'}/>
            ))}
        </div>
    );
}

export default ProductList;